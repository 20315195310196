<template>
    <ul class="sector-popout-menu">
        <li
            v-for="(link, index) in sectorMenu"
            class="project"
            :key="'item_'+index"
        >
            <router-link :to="{ path: link.link.uri }">
                <h2>{{link.title}}</h2>
                <span class="text">
                    {{link.description}}
                </span>
                <span class="button circle-arrow only-button">
                    <span class="icon">
                        <buttonArrowSVG/>
                    </span>
                </span>
            </router-link>
        </li>
    </ul>
</template>

<script>
    import buttonArrowSVG from '@/js/svg/buttonArrow.vue'

    export default {
        components: {
            buttonArrowSVG
        },
        computed: {
            sectorMenu() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.sectorMenu : null;
            }
        },
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .sector-popout-menu {
        min-height: 50%;
        list-style: none;
        width: 100%;
        color: $blue;
        background-color: $blue-lighter;
        display: grid;
        grid-template-columns: 1fr;
        gap: 0;

        li {
            display: flex;

            a {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-end;
                padding: $defaultPadding;
                color: inherit;
                text-decoration: none;

                h2 {
                    @include font-big;
                }

                h2, .button, .text {
                    flex: 1 0 100%;
                }

                .text {
                    padding: $defaultPadding 0;
                }
            }
        }

        @media (min-width: 380px) {
            grid-template-columns: 1fr 1fr;
        }

        @include tablet {
            max-width: 720px;
            min-height: none;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            gap: $defaultPadding*2;

            li {
                a {
                    padding: $defaultPadding*2.5;
                }
            }
        }
    }
</style>
