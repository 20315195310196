<template>
    <div class="page-wrapper home">
        <HomeHeader :content="content"/>
        <div class="background-color blue-lighter">
            <section class="default-layout sector-wrapper">
                <div class="sector-menu">
                    <div 
                        v-for="(link, index) in sectorMenu"
                        class="project"
                        :key="'item_'+index"
                    >
                        <router-link :to="{ path: link.link.uri }">
                            <span class="project-info">
                                <h2>{{link.title}}</h2>
                                <span class="text">
                                    {{link.description}}
                                </span>
                            </span>
                            <span class="button circle-arrow only-button hidden-xs">
                                <span class="icon">
                                    <buttonArrowSVG/>
                                </span>
                            </span>
                        </router-link>
                    </div>
                </div>
            </section>

            <section class="default-layout logo-wrapper">
                <div class="logo-slider">
                    <swiper
                        :slides-per-view="6"
                        :center-insufficient-slides="true"
                        :autoplay="{
                            delay: 1
                        }
                        "
                        :speed="4000"
                        :loop="this.content.slider.slides.length > 3"
                        :space-between="75"
                        :breakpoints="breakpoints"
                    >
                        <swiper-slide v-for="(slide, index) in content.slider.slides" :key="index">
                            <img :src="slide.image.src" :srcset="slide.image.srcset" alt=""/>                            
                        </swiper-slide>
                    </swiper>
                </div>
            </section>
        </div>
        

        <section class="default-layout intro-text">
            <span class="text" v-html="content.introText"></span>
        </section>

        <ProjectsOverview :content="content.projects"/>

        <div class="background-color blue-lighter">
            <PerdokCircle/>
            <TextWithImageBlue :content="content.action"/>
            <Cta v-if="content.cta" :content="content.cta"/>
        </div>

        <Parts :content="content.parts"/>

        <div class="background-color blue-lighter news">
            <NewsOverview :content="news" :title="'Ons nieuws'"/>
        </div>

    </div>
</template>

<script>
    import DefaultPage from '@/js/mixins/DefaultPage.vue'
    import HomeHeader from '@/js/components/Home/Header.vue'
    import PerdokCircle from '@/js/components/PerdokCircle/PerdokCircle.vue'
    import TextWithImageBlue from '@/js/components/contentBlocks/TextWithImageBlue.vue'
    import Cta from '@/js/components/Home/Cta.vue'
    import Parts from '@/js/components/Home/Parts.vue'
    import SectorMenu from '@/js/components/shared/SectorMenu.vue'
    import certificatesSVG from '@/js/svg/certificates.vue'
    import buttonArrowSVG from '@/js/svg/buttonArrow.vue'
    import {Autoplay} from 'swiper'
    import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
    import 'swiper/swiper-bundle.css'

    SwiperCore.use([Autoplay])

    export default {
        mixins: [DefaultPage],
        components: {
            HomeHeader,
            PerdokCircle,
            TextWithImageBlue,
            Cta,
            Parts,
            SectorMenu,
            certificatesSVG,
            buttonArrowSVG,
            Swiper,
            SwiperSlide,
        },
        computed: {
            sectorMenu() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.sectorMenu : null;
            }
        },
        data: function() {
            return {
              news: [],
              breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        loop: this.content.slider.slides.length > 3
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 4,
                        loop: this.content.slider.slides.length > 4
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 6,
                        loop: this.content.slider.slides.length > 6
                    }
                },
            }
        },
        mounted() {
            this.fetch();
        },
        methods: {
            fetch() {
                this.$http.get("/api/v1/news.json?limit=3")
                    .then(response => {
                        this.news = response.data.data;
                    });
                },
        },        
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper.home {
        .intro-text {
            @include grid-12;
            @include font-big;

            padding-top: $defaultPadding*4.5;
            .text {
                @include tablet {
                    grid-column: 3/ span 8;
                }
            }
        }

        .sector-wrapper {
            background-color: $white;
            padding: $defaultPadding;
            @include grid-12;
            .sector-menu {
                grid-column: 2/ span 10;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                .project {
                    display: flex;
                    
                    a {
                        color: inherit;
                        text-decoration: none;
                        display: flex;
                        justify-content: space-around;
                        padding: $defaultPadding * 1.5 0;
                        .project-info{
                            max-width: 75%;     
                            h2 {
                                @include font-big;
                            }

                            h2, .text {
                                flex: 1 0 100%;
                            }
                            .text {
                                display: block;
                                padding-top: $defaultPadding;
                            }
                        }
                        
                    }

                    &:first-child {
                        border-right: 2px solid $blue-lighter;
                    }
                    &:last-child {
                        justify-content: end;
                    }

                    @media(max-width: $mobile-width) {
                        .hidden-xs {
                            display: none;
                        }
                    }
                }
            }
        }

        .logo-wrapper {
            padding: $defaultPadding * 2 0;
            @media(min-width: $desktop-width) {
                @include grid-12;
            }
            .logo-slider {
                grid-column: 3/ span 8;
                .swiper-wrapper {
                    display: flex;
                    align-items: center;
                    -webkit-transition-timing-function:linear!important;
                    -o-transition-timing-function:linear!important;
                    transition-timing-function:linear!important;
                    .swiper-slide {
                        padding: 1px;
                        img {
                            max-width: 120px;
                        }
                    }
                }               
            }
        }

        .perdok-circle-wrapper {
            padding-bottom: $defaultPadding*3;
        }

        .text-with-image-blue-component, .cta-component-home {
            padding-bottom: $defaultPadding*4;
        }

        .news {
            padding-top: 1px;
            padding-bottom: 1px;
        }

        @include desktop {
            .action-component, .cta-component-home {
                padding-bottom: $defaultPadding*6;
            }
        }
    }
</style>

