<template>
    <ul class="sector-menu">
        <li 
            v-for="(link, index) in sectorMenu"
            class="project"
            :key="'item_'+index"
        >
            <router-link :to="{ path: link.link.uri }">
                <h2>{{link.title}}</h2>
                <span class="text">
                    {{link.description}}
                </span>
                <span class="button circle-arrow only-button">
                    <span class="icon">
                        <buttonArrowSVG/>
                    </span>
                </span>
            </router-link>
        </li>
    </ul>
</template>

<script>
    import buttonArrowSVG from '@/js/svg/buttonArrow.vue'

    export default {
        components: {
            buttonArrowSVG,
        },
        computed: {
            sectorMenu() {
                return this.$store.state.websiteData ? this.$store.state.websiteData.sectorMenu : null;
            }
        },
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .sector-menu {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .certificates {
            top: $defaultPadding;
            right: $defaultPadding;
            z-index: 3;
        }

        li {
            display: flex;

            a {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-end;
                color: inherit;
                text-decoration: none;
                padding: 0;

                h2 {
                    @include font-big;
                }

                h2, .button, .text {
                    flex: 1 0 100%;
                }

                .text {
                    padding: $defaultPadding 0;
                }
            }

            &:last-child {
                text-align: right;
                align-items: flex-end;
                flex-direction: column;
            }
        }

        @include tablet {
            li:not(:first-child) {
                a {
                    margin-left: $defaultPadding*2.5;
                }
            }
        }

        .show-desktop {
            display: none;
        }

        @include desktop {
            .show-desktop {
                display: block;
            }

            grid-template-columns: 1fr 1fr 1.5fr;
        }

        @media (max-width: $mobile-width) {
            grid-template-columns: 1fr;

            li {
                &:first-child {
                    margin-bottom: $defaultPadding * 2.5;
                }
            }
        }
    }
</style>

