<template>
    <div class="page-wrapper single" :class="pageClass">
        <span class="background" :class="$store.state.menuBgClass">
            <component 
                v-for="(component, index) in content.contentBlocks" 
                    :is="component.name"
                    :content="component.data" 
                    :index="index"
                    :nextComponentType="content.contentBlocks[index+1] ? content.contentBlocks[index+1].name : null"
                    :key="component.name+'_'+index"
            />
        </span>
        <CtaComponent v-if="content.cta" :content="content.cta"/>
    </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    
    export default {
        mixins: [DefaultPage],
        data: function() {
            return {
              
            }
        },
        computed: {

        },
        methods: {
          
        },
        mounted() {
            
        },
        created() {
           
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper.single {
        .background {
            display: block;
            
            &.blue-lighter {
                background-color: $blue-lighter;
            }

            &.white {
                background-color: $white;
            }
        }
    }
</style>

